<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    persistent
  >
    <v-card>
      <v-toolbar color="title" dark flat short class="px-2">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
      </v-toolbar>
      <v-card-text class="mt-12">
        {{ message }}
      </v-card-text>
      <v-card-actions class="mt-3 px-6 pb-8">
        <v-spacer></v-spacer>
        <v-btn
          v-if="labelCancel"
          color="primary"
          class="px-5"
          outlined
          depressed
          @click="handleCancel()"
        >{{ labelCancel }}</v-btn>
        <v-btn
          :color="labelConfirmColor"
          class="px-5"
          depressed
          @click="handleConfirm()"
        >{{ labelConfirm }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from 'vue'

export default {
  props: {
    title: {
      type: String,
      default: 'Bevestigen',
    },
    message: {
      type: String,
      default: 'Weet je het zeker?',
    },
    labelConfirm: {
      type: String,
      default: 'OK',
    },
    labelConfirmColor: {
      type: String,
      default: 'primary',
    },
    labelCancel: {
      type: String,
      default: 'Annuleren',
    },
    labelCancelColor: {
      type: String,
      default: 'default',
    },
  },
  setup(props, context) {

    const dialog = ref(false)
    let _params = {}

    function show(params) {
      _params = params || {}
      dialog.value = true
    }

    function handleConfirm() {
      context.emit('confirm', _params)
      dialog.value = false
    }

    function handleCancel() {
      context.emit('cancel', _params)
      dialog.value = false
    }

    function hide() {
      dialog.value = false
    }

    return {
      dialog,
      show,
      hide,
      handleConfirm,
      handleCancel,
    }
  }
}
</script>
