<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card>
      <v-toolbar color="primary" dark flat short class="px-2">
        <v-toolbar-title>Image {{ index }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()" icon><v-icon>fas fa-times</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="mt-12">
        <v-form @submit.prevent>

          <v-card
            flat
            class="mx-16"
          >
            <div
              class="image bordered"
              @drop.prevent="dropFiles"
              @dragenter.prevent
              @dragover.prevent
            >
              <v-img
                v-if="attributes[`image${index}_url`]"
                :src="attributes[`image${index}_url`]"
                contain
                max-height="200"
              ></v-img>
            </div>

            <file-drop-input
              outlined
              class="mt-8"
              v-model="file"
              :error-messages="errors[`image${index}_url`]"
              clear-icon="fas fa-times"
              accept="image/*"
              label="Image"
              @change="handleData"
            ></file-drop-input>

          </v-card>
              
        </v-form>
      </v-card-text>

      <v-card-actions class="px-6 pb-8">
        <v-btn
          v-if="attributes.id"
          color="error"
          class="px-5"
          large
          outlined
          depressed
          @click="remove()"
        >{{ deleteButtonText }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="px-5"
          large
          outlined
          depressed
          @click="cancel()"
        >{{ cancelButtonText }}</v-btn>
        <v-btn
          color="primary"
          class="px-5"
          large
          depressed
          @click="save()"
        >{{ saveButtonText }}</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
/* eslint-disable */
import { ref, watch } from 'vue'
import { readFileAsDataUrl } from '@/utils'
import FileDropInput from '@/components/FileDropInput'

export default {
  inheritAttrs: false,
  components: {
    FileDropInput,
  },
  props: {
    index: { default: 1 },
    value: Boolean,
    attributes: Object,
    errors: Object,
    colors: Array,
    cancelButtonText: {
      default: 'Cancel',
    },
    saveButtonText: {
      default: 'Save',
    },
    deleteButtonText: {
      default: 'Delete',
    },
  },
  setup(props, context) {
    const dialog = ref(false)
    const file = ref()

    watch(() => props.value, val => {
      if (val) {
        file.value = null
      }
      dialog.value = val
    })

    watch(dialog, val => {
      context.emit('input', val)
    })

    function save() {
      handleData(file.value).then(() => {
        context.emit('save')
      })
    }

    function remove() {
      context.emit('delete')
    }

    function handleData(file) {
      return new Promise((resolve, reject) => {
        if (file) {
          readFileAsDataUrl(file).then(url => {
            props.attributes[`image${props.index}_url`] = url
            resolve(url)
          }).catch(err => {
            props.attributes[`image${props.index}_url`] = null
            reject(err)
          })
        } else {
          resolve()
        }
      })
    }

    function cancel() {
      dialog.value = false
      context.emit('cancel')
    }

    function show() {
      dialog.value = true
    }

    function hide() {
      dialog.value = false
    }

    function dropFiles(e) {
      const droppedFiles = Array.from(e.dataTransfer.files)
      if (droppedFiles.length) {
        file.value = droppedFiles[0]
        handleData(file.value)
      }
    }


    return {
      dialog,
      file,
      handleData,

      save,
      cancel,
      remove,

      show,
      hide,
      dropFiles,
    }
  }}
</script>

<style scoped>

.image {
  height: 200px;
}

.image.bordered {
  border: 1px solid #eeeeee;
}
</style>