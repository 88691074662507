<template>
  <div>
    <v-breadcrumbs
      class="pt-3 pl-7"
      :items="[{ text: 'Products', exact: true,  to: { name: 'products' } }, { text: oldProduct.name }]"
    >
    </v-breadcrumbs>

    <v-row
      class="mt-0 mb-8"
    >

      <v-col
        cols="12"
        md="6"
      >

        <v-card
          flat
          class="mx-3 pb-8"
        >
          <v-toolbar
            dark
            class="tertiary"
            dense
            elevation="0"
          >
            <v-toolbar-title>Product</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="exportOdoo()"
              :disabled="!user.can('product.odoo')"
            >
              <v-icon color="secondary" small>fas fa-download</v-icon>
            </v-btn>

            <v-btn
              icon
              @click="odooDialog = true"
              :disabled="!user.can('product.odoo')"
            >
              <v-icon color="secondary" small>fas fa-sync</v-icon>
            </v-btn>

            <v-btn
              icon
              @click="showProductDialog()"
              :disabled="!user.can('product.update')"
            >
              <v-icon color="secondary" small>fas fa-edit</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>

            <v-row>
              <v-col cols="12" md="6">
                <dl class="row table mt-5">
                  <dt class="col-6">Code:</dt>
                  <dd class="col-6">{{ oldProduct.code }}</dd>
                  <dt class="col-6">Name:</dt>
                  <dd class="col-6">{{ oldProduct.name }}</dd>
                  <dt class="col-6">Category:</dt>
                  <dd class="col-6"><template v-if="oldProduct.category">{{ oldProduct.category.name }}</template></dd>
                  <dt class="col-6">Brand:</dt>
                  <dd class="col-6"><template v-if="oldProduct.brand">{{ oldProduct.brand.name }}</template></dd>
                  <dt class="col-6">Preferred Supplier 1:</dt>
                  <dd class="col-6"><template v-if="oldProduct.preferred_supplier_1">{{ oldProduct.preferred_supplier_1.name }}</template></dd>
                  <dt class="col-6">Preferred Supplier 2:</dt>
                  <dd class="col-6"><template v-if="oldProduct.preferred_supplier_2">{{ oldProduct.preferred_supplier_2.name }}</template></dd>
                  <dt class="col-6">Preferred Supplier 3:</dt>
                  <dd class="col-6"><template v-if="oldProduct.preferred_supplier_3">{{ oldProduct.preferred_supplier_3.name }}</template></dd>
                  <template v-if="user.can('show:sensitive')">
                    <dt class="col-6">Margin:</dt>
                    <dd class="col-6">{{ formatPercentage(oldProduct.margin) }}</dd>
                  </template>
                </dl>
              </v-col>
              <v-col cols="12" md="6">
                <v-img
                  v-if="oldProduct.image_url"
                  :src="oldProduct.image_url"
                  contain
                  max-height="200"
                ></v-img>
              </v-col>
            </v-row>

          </v-card-text>


          <product-dialog
            v-model="productDialog"
            :attributes="product"
            :errors="productErrors"
            :categories="categories"
            :suppliers="suppliers"
            :brands="brands"
            @save="saveProduct()"
            @delete="deleteProduct()"
          ></product-dialog>

          <odoo-product-dialog
            v-model="odooDialog"
            :products="[product]"
          ></odoo-product-dialog>

        </v-card>




      </v-col>


      <v-col
        cols="12"
        md="6"
      >

        <v-card
          flat
          class="mx-3 pb-9"
        >
          <v-toolbar
            dark
            class="tertiary"
            dense
            elevation="0"
          >
            <v-toolbar-title>Attributes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="showProductAttributeDialog()"
              :disabled="!user.can('product.update')"
            >
              <v-icon color="secondary" small>fas fa-edit</v-icon>
            </v-btn>
          </v-toolbar>

            <v-simple-table class="mt-5">
              <thead>
                <tr>
                  <th>Attribute</th>
                  <th>Values</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(attribute, index) in activeAttributes" :key="'attr'+index">
                  <td>{{ attribute.name }}</td>
                  <td>
                    <v-chip
                      small
                      v-for="(value, index) in attribute.values"
                      :key="'attrval'+index"
                      class="mr-2"
                    >{{ value.name }}</v-chip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>


          <product-attribute-dialog
            v-model="productAttributeDialog"
            :attribute-value-ids="product.attribute_value_ids"
            :attributes="attributes"
            @save="saveProductAttributes"
          ></product-attribute-dialog>

        </v-card>
      </v-col>


      <v-col
        cols="12"
        md="6"
      >

        <v-card
          flat
          class="mx-3 pb-9"
        >
          <v-toolbar
            dark
            class="tertiary"
            dense
            elevation="0"
          >
            <v-toolbar-title>Variant Images</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-switch
              v-model="dropImageEnabled"
              color="secondary"
              class="mt-5"
              :disabled="!user.can('product.update')"
            ></v-switch>

          </v-toolbar>

          <v-row
            class="my-5"
          >
            <v-col
              cols="4"
              v-for="(productImage, index) in productImages"
              :key="'image'+index"
            >
              <v-card
                flat
                class="mx-3"
              >

                <div class="d-flex flex-col">

                  <div
                    :class="{ 'clickable': user.can('product.update') }"
                    class="image bordered me-1"
                    style="position:relative;width: 50%"
                    @click="showProductImageDialog(productImage.id, 1)"
                    @drop.prevent="(e) => dropProductImageFiles(e, productImage.id, 1)"
                    @dragenter.prevent
                    @dragover.prevent
                  >

                    <span
                      style="position:absolute;left:50%;bottom:0;transform: translateX(-50%);"
                      class="caption"
                    >front</span>

                    <div
                      v-if="productImage && productImage.image1_url"
                      :style="{ background: `url('${productImage.image1_url}') center center / contain no-repeat`, width: '100%', height: '100%' }"
                    ></div>
                  </div>

                  <div
                    :class="{ 'clickable': user.can('product.update') }"
                    class="image bordered ms-1"
                    style="position:relative;width: 50%"
                    @click="showProductImageDialog(productImage.id, 2)"
                    @drop.prevent="(e) => dropProductImageFiles(e, productImage.id, 2)"
                    @dragenter.prevent
                    @dragover.prevent
                  >

                    <span
                      style="position:absolute;left:50%;bottom:0;transform: translateX(-50%);"
                      class="caption"
                    >back</span>

                    <div
                      v-if="productImage && productImage.image2_url"
                      :style="{ background: `url('${productImage.image2_url}') center center / contain no-repeat`, width: '100%', height: '100%' }"
                    ></div>

                  </div>
                </div>

                <v-card-text class="px-0">
                  <v-chip-group>
                    <v-chip v-if="productImage.primary_color">{{ productImage.primary_color.name }}</v-chip>
                    <v-chip v-if="productImage.secondary_color">{{ productImage.secondary_color.name }}</v-chip>
                    <v-chip v-if="productImage.tertiary_color">{{ productImage.tertiary_color.name }}</v-chip>
                    <v-chip v-if="productImage.quaternary_color">{{ productImage.quaternary_color.name }}</v-chip>
                  </v-chip-group>
                </v-card-text>

              </v-card>
            </v-col>
          </v-row>

          <product-image-dialog
            v-model="productImageDialog"
            :index="productImageIndex"
            :attributes="productImage"
            :errors="productImageErrors"
            :colors="colors"
            @save="saveProductImage()"
            @delete="deleteProductImage()"
          ></product-image-dialog>

        </v-card>
      </v-col>


      <v-col
        cols="12"
        md="6"
      >

        <v-card
          flat
          class="mx-3 pb-3"
        >

          <v-toolbar
            dark
            class="tertiary"
            dense
            elevation="0"
          >
            <v-toolbar-title>Variants</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              dense
              background-color="primary"
              v-model="filter.query"
              label="Search..."
              single-line
              hide-details
              solo
              @keyup="variantSearch()"
            ></v-text-field>
            <v-btn
              icon
              color="secondary"
              @click="showVariantDialog()"
              :disabled="!user.can('product-variant.create')"
            >
              <v-icon small>fas fa-plus</v-icon>
            </v-btn>
          </v-toolbar>

          <v-data-table
            class="mt-5 clickable"
            :headers="variantHeaders"
            :items="variants"
            :loading="isSearching"
            :options.sync="options"
            :server-items-length="totalCount"
            :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
            @click:row="gotoVariant"
          >

            <template v-slot:item.sales_price="{ item }">
              {{ formatCurrency(item.sales_price) }}
            </template>

            <template v-slot:item.is_active="{ item }">
              <v-icon :color="item.is_active ? 'secondary' : 'grey'" x-small>fas fa-circle</v-icon>
            </template>

          </v-data-table>

          <v-card-actions
            class="mx-3"
          >
            <v-btn
              depressed
              outlined
              class="red--text"
              @click="showDeleteProductSupplierDialog()"
            >Delete Supplier</v-btn>
          </v-card-actions>


          <product-variant-dialog
            v-model="variantDialog"
            :attributes="variant"
            :errors="variantErrors"
            :sizes="sizes"
            :colors="colors"
            save-button-text="Create"
            @save="saveVariant()"
          ></product-variant-dialog>

          <delete-product-supplier-dialog
            v-model="deleteProductSupplierDialog"
            :suppliers="productSuppliers"
            @delete="deleteProductSupplier"
          ></delete-product-supplier-dialog>

        </v-card>
      </v-col>

    </v-row>


  </div>
</template>

<script>
/* eslint-disable */
import { inject, ref, watch, computed } from 'vue'
import ProductDialog from '@/components/ProductDialog.vue'
import ProductVariantDialog from '@/components/ProductVariantDialog.vue'
import ProductAttributeDialog from '@/components/ProductAttributeDialog.vue'
import ProductImageDialog from '@/components/ProductImageDialog.vue'
import OdooProductDialog from '@/components/OdooProductDialog.vue'
import DeleteProductSupplierDialog from '@/components/DeleteProductSupplierDialog.vue'
import { readFileAsDataUrl, formatCurrency, formatPercentage } from '@/utils'
import { useRouter, useRoute } from 'vue-router/composables'

export default {
  components: {
    ProductDialog,
    ProductVariantDialog,
    ProductAttributeDialog,
    ProductImageDialog,
    OdooProductDialog,
    DeleteProductSupplierDialog,
  },
  setup() {
    const api = inject('api')
    const { user } = api
    const router = useRouter()
    const route = useRoute()

    // variants
    const variantCollection = api.collection('product-variants', {
      params: {
      },
      filter: {
        product_id: 'eq',
        query: 'query:description,unique_identifier',
      },
    })

    const { items: variants, filter, options, totalCount, isSearching } = variantCollection

    const variantHeaders = [
      { text: 'Description', value: 'description', },
      user.can('show:sensitive') ? { text: 'Sales Price', value: 'sales_price' } : null,
      { text: 'Active', value: 'is_active', align: 'center' },
    ].filter(header => header !== null)

    const variantSearch = variantCollection.debounceSearch(300)

    // product
    const productModel = api.model('products/:id', {
      params: {
        expand: 'category,brand,preferred_supplier_1,preferred_supplier_2,preferred_supplier_3,attribute_values,image_url',
      },
      attributes: {
        image_url: null,
      },
    })

    // delete supplier
    const deleteProductSupplierDialog  = ref(false)

    const productSupplierCollection = api.collection()
    const { items: productSuppliers } = productSupplierCollection

    function showDeleteProductSupplierDialog() {
      deleteProductSupplierDialog.value = true
    }

    function deleteProductSupplier(id) {
      api.client.delete(`products/${productModel.getAttribute('id')}/suppliers/${id}`).then(() => {
        productSupplierCollection.all()
        deleteProductSupplierDialog.value = false
      })
    }



    const { attributes: product, errors: productErrors, oldAttributes: oldProduct } = productModel
    const productDialog = ref(false)

    function saveProduct() {
      productModel.save().then(() => {
        variantCollection.search()
        productDialog.value = false
      })
    }

    function showProductDialog() {
      productModel.reset()
      productDialog.value = true
    }

    const { items: categories } = api.collection('categories').all()
    const { items: brands } = api.collection('brands').all()
    const { items: suppliers } = api.collection('suppliers').all()

    // variant
    const variantModel = api.model('product-variants/:id', {
    })

    const { attributes: variant, errors: variantErrors } = variantModel
    const variantDialog = ref(false)
    

    const { items: sizes } = api.collection('sizes').all()
    const { items: colors } = api.collection('colors').all()

    function showVariantDialog() {
      variantModel.reset().setAttribute('product_id', productModel.getAttribute('id'))
      variantDialog.value = true
    }

    function saveVariant() {
      variantModel.save().then(() => {
        variantCollection.search()
        variantDialog.value = false
      })
    }

    function gotoVariant(item) {
      router.push({
        name: 'variant',
        params: { id: item.id },
      })
    }

    // attributes
    const productAttributeDialog = ref(false)

    const attributeCollection = api.collection('attributes', {
      params: {
        expand: 'values',
      }
    })

    const { items: attributes } = attributeCollection.all()

    const activeAttributes = computed(() => {
      const activeAttributes = {}

      attributes.value.forEach(attribute => {
        attribute.values.forEach(value => {
          if (oldProduct.value.attribute_value_ids && product.value.attribute_value_ids.includes(value.id)) {
            if (!activeAttributes[value.attribute_id]) {
              activeAttributes[value.attribute_id] = Object.assign({}, attribute, { values: [] })
            }
            activeAttributes[value.attribute_id].values.push(value)
          }
        })
      })

      return Object.values(activeAttributes)
    })

    function showProductAttributeDialog() {
      productAttributeDialog.value = true
    }

    function saveProductAttributes(attributeValueIds) {
      productModel.setAttribute('attribute_value_ids', attributeValueIds).save().then(() => {
        productAttributeDialog.value = false
      })
    }

    // image
    const dropImageEnabled = ref(false)
    const productImageDialog = ref(false)
    const productImageIndex = ref()

    const productImageCollection = api.collection('product-images', {
      params: {
        expand: 'image1_url,image2_url,primary_color,secondary_color,tertiary_color,quaternary_color',
        filter: { is_active: { eq: 1 } },
      },
      filter: {
        product_id: 'eq',
      },
    })

    const { items: productImages } = productImageCollection

    const productImageModel = api.model('product-images/:id', {
      params: {
        expand: 'image1_url,image2_url',
      },
      attributes: {
        image1_url: null,
        image2_url: null,
      },
    })

    const { attributes: productImage, errors: productImageErrors } = productImageModel

    function dropProductImageFiles(e, id, index) {
      if (dropImageEnabled.value) {
        const droppedFiles = Array.from(e.dataTransfer.files)
        if (droppedFiles.length) {
          let file = droppedFiles[0]
          readFileAsDataUrl(file).then(url => {
            productImageModel.clear().find(id).then(() => {
              productImageModel.setAttribute(`image${index}_url`, url)
              productImageModel.save().then(() => {
                productImageCollection.all()
              })
            })
          })
        }
      }
    }

    function showProductImageDialog(id, index) {
      if (!dropImageEnabled.value && user.can('product.update')) {
        productImageIndex.value = index
        productImageModel.clear().find(id)
        productImageDialog.value = true
      }
    }


    function saveProductImage() {
      productImageModel.save().then(() => {
        productImageDialog.value = false
        productImageCollection.all()
      })
    }

    function deleteProduct() {
      productModel.delete().then(() => {
        router.push({
          name: 'products',
        })
      })
    }

    function deleteProductImage() {
      productImageModel.setAttribute(`remove_image${productImageIndex.value}`, true).save().then(() => {
        productImageDialog.value = false
        productImageCollection.all()
      }).finally(() => {
        productImageModel.setAttribute(`remove_image1`, false).setAttribute(`remove_image2`, false)
      })
    }

    // odoo
    const odooDialog = ref(false)

    function showOdooDialog() {
      odooDialog.value = true
    }

    function exportOdoo() {
      api.client.get(`products/${productModel.getAttribute('id')}/odoo-export`, { responseType: 'blob' }).then(response => {

          const url = URL.createObjectURL(response.data)
          console.log(url)

          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `odoo-export-${productModel.getAttribute('id')}.json`)
          document.body.appendChild(link)
          link.click()
          
          setTimeout(() => {
            document.body.removeChild(link)
            URL.revokeObjectURL(url)
          }, 200)
      })

    }


    // route

    watch(() => route, ({ params }) => {
      if (params.id) {
        productModel.find(params.id).catch(() => {
          router.push({
            name: 'products',
          })
        })
        variantCollection.setFilter('product_id', params.id).search()
        productImageCollection.setFilter('product_id', params.id).all()
        productSupplierCollection.setPath(`products/${params.id}/suppliers`).all()
      }
    }, { immediate: true })



    return {
      user: api.user,
      productDialog,
      productModel,

      product,
      productErrors,
      oldProduct,
      showProductDialog,
      saveProduct,
      deleteProduct,

      colors,
      categories,
      brands,
      sizes,
      suppliers,

      variantHeaders,
      variants,
      filter,
      options,
      totalCount,
      isSearching,
      variantSearch,

      variantDialog,
      variant,
      variantErrors,
      showVariantDialog,
      saveVariant,
      gotoVariant,

      productAttributeDialog,
      attributes,
      activeAttributes,
      showProductAttributeDialog,
      saveProductAttributes,

      productImageDialog,
      showProductImageDialog,
      productImages,
      productImage,
      productImageErrors,
      saveProductImage,
      deleteProductImage,
      productImageIndex,

      dropImageEnabled,
      dropProductImageFiles,

      odooDialog,


      formatCurrency,
      formatPercentage,

      productSuppliers,
      deleteProductSupplierDialog,
      showDeleteProductSupplierDialog,
      deleteProductSupplier,

      exportOdoo,
    }

  },
}
</script>

<style scoped>

:deep(.v-data-table.clickable table tbody tr:not(.v-data-table__empty-wrapper):hover) {
  cursor: pointer;
}

.image {
  height: 200px;
}

.image.clickable:hover {
  cursor: pointer;
}

.image.bordered {
  border: 1px solid #eeeeee;
}

dl.table dt, dl.table dd {
  padding-top: 2px;
  padding-bottom: 2px;
}


</style>