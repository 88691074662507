<template>
  <v-dialog
    v-model="dialog"
    max-width="1100"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card>
      <v-toolbar color="primary" dark flat short class="px-2">
        <v-toolbar-title>Variant</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()" icon><v-icon>fas fa-times</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="mt-12">
        <v-form @submit.prevent>

          <v-row>
            <v-col cols="6">
              <v-text-field
                outlined
                class="mb-2"
                v-model="attributes.description"
                :error-messages="errors.description"
                label="Description"
                disabled
              ></v-text-field>

              <number-field
                outlined
                class="mb-2"
                v-model="attributes.weight"
                :error-messages="errors.weight"
                label="Weight"
                decimals="3"
                decimal-separator="."
                suffix="kg"
              ></number-field>

              <number-field
                outlined
                class="mb-2"
                v-model="attributes.sales_price"
                :error-messages="errors.sales_price"
                label="Sales Price"
                decimals="2"
                decimal-separator="."
                prefix="€"
                disabled
              ></number-field>

              <v-checkbox
                v-model="attributes.is_active"
                :error-messages="errors.is_active"
                label="Active"
                :false-value="0"
                :true-value="1"
              ></v-checkbox>


            </v-col>
            <v-col cols="6">

              <v-select
                outlined
                clearable
                clear-icon="fas fa-times"
                class="mb-2"
                v-model="attributes.size_id"
                :error-messages="errors.size_id"
                item-text="name"
                item-value="id"
                :items="sizes"
                label="Size"
              ></v-select>

              <v-select
                outlined
                clearable
                clear-icon="fas fa-times"
                class="mb-2"
                v-model="attributes.primary_color_id"
                :error-messages="errors.primary_color_id"
                item-text="name"
                item-value="id"
                :items="colors"
                label="Primary Color"
              ></v-select>

              <v-select
                outlined
                clearable
                clear-icon="fas fa-times"
                class="mb-2"
                v-model="attributes.secondary_color_id"
                :error-messages="errors.secondary_color_id"
                item-text="name"
                item-value="id"
                :items="colors"
                label="Secondary Color"
              ></v-select>

              <v-select
                outlined
                clearable
                clear-icon="fas fa-times"
                class="mb-2"
                v-model="attributes.tertiary_color_id"
                :error-messages="errors.tertiary_color_id"
                item-text="name"
                item-value="id"
                :items="colors"
                label="Tertiary Color"
              ></v-select>

              <v-select
                outlined
                clearable
                clear-icon="fas fa-times"
                class="mb-2"
                v-model="attributes.quaternary_color_id"
                :error-messages="errors.quaternary_color_id"
                item-text="name"
                item-value="id"
                :items="colors"
                label="Quaternary Color"
              ></v-select>



            </v-col>
          </v-row>

        </v-form>
      </v-card-text>

      <v-card-actions class="px-6 pb-8">
        <v-btn
          v-if="attributes.id"
          color="error"
          class="px-5"
          large
          outlined
          depressed
          @click="$refs.deleteProductVariantConfirmDialog.show()"
        >{{ deleteButtonText }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="px-5"
          large
          outlined
          depressed
          @click="cancel()"
        >{{ cancelButtonText }}</v-btn>
        <v-btn
          color="primary"
          class="px-5"
          large
          depressed
          @click="save()"
        >{{ saveButtonText }}</v-btn>
      </v-card-actions>

      <confirm-dialog
        ref="deleteProductVariantConfirmDialog"
        title="Delete"
        message="Are you sure?"
        label-cancel="Cancel"
        @confirm="deleteProductVariant()"
      ></confirm-dialog>


    </v-card>

  </v-dialog>
</template>

<script>
import { ref, watch } from 'vue'
import NumberField from '@/components/NumberField'
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  components: {
    NumberField,
    ConfirmDialog,
  },
  props: {
    value: Boolean,
    attributes: Object,
    errors: Object,
    sizes: Array,
    colors: Array,
    cancelButtonText: {
      default: 'Cancel',
    },
    saveButtonText: {
      default: 'Save',
    },
    deleteButtonText: {
      default: 'Delete',
    },
  },
  setup(props, context) {
    const dialog = ref(false)

    watch(() => props.value, val => {
      dialog.value = val
    })

    watch(dialog, val => {
      context.emit('input', val)
    })

    function save() {
      context.emit('save')
    }

    function cancel() {
      dialog.value = false
      context.emit('cancel')
    }
    
    function show() {
      dialog.value = true
    }

    function hide() {
      dialog.value = false
    }

    function deleteProductVariant() {
      context.emit('delete')
    }

    return {
      dialog,

      save,
      cancel,

      show,
      hide,
      deleteProductVariant,
    }
  }
}

</script>