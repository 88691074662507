<template>
  <v-dialog
    v-model="dialog"
    max-width="1100"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card>
      <v-toolbar color="primary" dark flat short class="px-2">
        <v-toolbar-title>Product</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()" icon><v-icon>fas fa-times</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="mt-12">
        <v-form @submit.prevent>

          <v-row>
            <v-col cols="6">

              <v-text-field
                outlined
                class="mb-2"
                v-model="attributes.code"
                :error-messages="errors.code"
                :disabled="!!attributes.id"
                label="Code"
              ></v-text-field>

              <v-text-field
                outlined
                class="mb-2"
                v-model="attributes.name"
                :error-messages="errors.name"
                label="Name"
              ></v-text-field>

              <v-autocomplete
                outlined
                clearable
                clear-icon="fas fa-times"
                class="mb-2"
                v-model="attributes.category_id"
                :error-messages="errors.category_id"
                item-text="name"
                item-value="id"
                :items="categories"
                label="Category"
              ></v-autocomplete>

              <v-autocomplete
                outlined
                clearable
                clear-icon="fas fa-times"
                class="mb-2"
                v-model="attributes.brand_id"
                :error-messages="errors.brand_id"
                item-text="name"
                item-value="id"
                :items="brands"
                :disabled="!!attributes.id"
                label="Brand"
              ></v-autocomplete>

              <v-autocomplete
                outlined
                clearable
                clear-icon="fas fa-times"
                class="mb-2"
                v-model="attributes.preferred_supplier_1_id"
                :error-messages="errors.preferred_supplier_1_id"
                item-text="name"
                item-value="id"
                :items="suppliers"
                label="Preferred Supplier 1"
              ></v-autocomplete>

              <v-autocomplete
                outlined
                clearable
                clear-icon="fas fa-times"
                class="mb-2"
                v-model="attributes.preferred_supplier_2_id"
                :error-messages="errors.preferred_supplier_2_id"
                item-text="name"
                item-value="id"
                :items="suppliers"
                label="Preferred Supplier 2"
              ></v-autocomplete>

              <v-autocomplete
                outlined
                clearable
                clear-icon="fas fa-times"
                class="mb-2"
                v-model="attributes.preferred_supplier_3_id"
                :error-messages="errors.preferred_supplier_3_id"
                item-text="name"
                item-value="id"
                :items="suppliers"
                label="Preferred Supplier 3"
              ></v-autocomplete>

              <number-field
                outlined
                class="mb-2"
                v-model="attributes.margin"
                :error-messages="errors.margin"
                decimals="2"
                decimal-separator="."
                label="Margin"
                suffix="%"
                disabled
              ></number-field>



            </v-col>
            <v-col cols="6">

                <v-card
                  flat
                  class="mx-16"
                >

                  <div class="image bordered">
                    <v-img
                      v-if="attributes.image_url"
                      :src="attributes.image_url"
                      contain
                      max-height="200"
                    ></v-img>
                  </div>

                  <file-drop-input
                    outlined
                    class="mt-8"
                    v-model="file"
                    :error-messages="errors.image_url"
                    clear-icon="fas fa-times"
                    accept="image/*"
                    label="Product Image"
                    @change="handleData"
                  ></file-drop-input>

                </v-card>

              

            </v-col>
          </v-row>

        </v-form>
      </v-card-text>

      <v-card-actions class="px-6 pb-8">
        <v-btn
          v-if="attributes.id"
          color="error"
          class="px-5"
          large
          outlined
          depressed
          @click="$refs.deleteProductConfirmDialog.show()"
        >{{ deleteButtonText }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="px-5"
          large
          outlined
          depressed
          @click="cancel()"
        >{{ cancelButtonText }}</v-btn>
        <v-btn
          color="primary"
          class="px-5"
          large
          depressed
          @click="save()"
        >{{ saveButtonText }}</v-btn>
      </v-card-actions>

      <confirm-dialog
        ref="deleteProductConfirmDialog"
        title="Delete"
        message="Are you sure?"
        label-cancel="Cancel"
        @confirm="deleteProduct()"
      ></confirm-dialog>

    </v-card>

  </v-dialog>
</template>

<script>
/* eslint-disable */
import { ref, watch } from 'vue'
import { readFileAsDataUrl } from '@/utils'
import FileDropInput from '@/components/FileDropInput'
import NumberField from '@/components/NumberField'
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  components: {
    FileDropInput,
    NumberField,
    ConfirmDialog,
  },
  props: {
    value: Boolean,
    attributes: Object,
    errors: Object,
    categories: Array,
    brands: Array,
    suppliers: Array,
    cancelButtonText: {
      default: 'Cancel',
    },
    saveButtonText: {
      default: 'Save',
    },
    deleteButtonText: {
      default: 'Delete',
    },
  },
  setup(props, context) {
    const dialog = ref(false)
    const file = ref()

    watch(() => props.value, val => {
      if (val) {
        file.value = null
      }
      dialog.value = val
    })

    watch(dialog, val => {
      context.emit('input', val)
    })

    function save() {
      handleData(file.value).then(() => {
        context.emit('save')
      })
    }

    function deleteProduct() {
      context.emit('delete')
    }

    function handleData(file) {
      return new Promise((resolve, reject) => {
        if (file) {
          readFileAsDataUrl(file).then(url => {
            props.attributes.image_url = url
            resolve(url)
          }).catch(err => {
            props.attributes.image_url = null
            reject(err)
          })
        } else {
          resolve()
        }
      })
    }

    function cancel() {
      dialog.value = false
      context.emit('cancel')
    }

    function show() {
      dialog.value = true
    }

    function hide() {
      dialog.value = false
    }

    return {
      dialog,
      file,
      handleData,

      save,
      cancel,
      deleteProduct,

      show,
      hide,
    }
  }}
</script>

<style scoped>

.image {
  height: 200px;
}

.image.bordered {
  border: 1px solid #eeeeee;
}
</style>