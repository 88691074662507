<template>
  <v-text-field
    v-model="model"
    v-bind="$attrs"
    v-on="listeners"
    @keypress="validateNumber"
    @change="updateValue"
  ></v-text-field>
</template>

<script>

import { ref, watch } from 'vue'

export default {
  props: {
    value: [Number, String],
    decimalSeparator: {
      type: String,
      default: ',',
      validator: (value) => value.length === 1,
    },
    decimals: {
      default: null,
    },
    integer: {
      type: Boolean,
      default: false,
    },
    positive: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, context) {

    const model = ref()

    watch(() => props.value, value => {
      model.value = santizeValue(value)
    }, { immediate: true })

    const listeners = Object.assign({}, context.listeners)
    delete listeners.input
    delete listeners.keypress
    delete listeners.change

    function updateValue(stringValue) {
      stringValue = stringValue.replace(props.decimalSeparator.charAt(0), '.')
      let numberValue = Number(stringValue)
      let value = isNumber(props.value) ? numberValue : stringValue

      // only update model if number values are different
      if (numberValue !== Number(props.value)) {
        context.emit('input', value)
        context.emit('change', value)
      }
    }

    function santizeValue(value) {
      value = String(value).replace(/[^\d.-]/g, '') // remove all dirty characters
        // .replace(/(?<=\..*)\./g, '') // remove all dots except first
        // .replace(/(?!^)-/g, '') // remove all minuses except at first position

      // find first dot
      let firstDotPosition = value.indexOf('.')

      if (firstDotPosition !== -1) {
        value = value.substr(0, firstDotPosition + 1) + value.substr(firstDotPosition + 1).replace(/\./g, '')
      }

      // minus
      if (value.length > 1) {
        value = value.substr(0, 1) + value.substr(1).replace(/-/g, '')
      }

      if (props.positive) {
        value = value.replace(/-/, '')
      }

      if (value.length === 0 || value === '-' || value === '-.' || value.charAt(0) === '.') {
        return ''
      }

      if (props.integer) {
        return String(Math.floor(Number(value)))
      }

      return props.decimals !== null
        ? Number(value).toFixed(props.decimals).replace(/\./, props.decimalSeparator.charAt(0))
        : String(Number(value)).replace(/\./, props.decimalSeparator.charAt(0))
    }

    function isNumber(value) {
      return typeof value === 'number' && isFinite(value)
    }

    function validateNumber(e) {
      const charSeparator = props.decimalSeparator.charAt(0)
      const charCodeSeparator = charSeparator.charCodeAt(0)

      let value = e.target.value

      // minus at first position
      if (!props.positive && e.which === 45 && e.target.selectionStart === 0) {
        return true
      }

      // only one occurrance of separator
      if (!props.integer && e.which === charCodeSeparator && value.length && value.indexOf(charSeparator) === -1) {
        return true
      }

      // todo: separator at first position or second with minus, prepend with '0'

      // digits
      if (e.which >= 48 && e.which <= 57) {
        return true
      }

      e.preventDefault()

      context.emit('keypress', e)
    }


    return {
      model,
      listeners,
      validateNumber,
      updateValue,
    }

  }
}
</script>
