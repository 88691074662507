<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card>
      <v-toolbar color="primary" dark flat short class="px-2">
        <v-toolbar-title>Odoo Synchronisation</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()" icon><v-icon>fas fa-times</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="mt-12">

        <p>Synchronise {{ products ? products.length : 0 }} product(s)
          <span class="ml-5">
            <v-progress-circular v-if="isBusy" :size="30" color="grey" indeterminate></v-progress-circular>
            <v-chip v-if="isDone && isOk" color="green">OK</v-chip>
            <v-chip v-if="isDone && !isOk" color="error">Error</v-chip>
          </span>
        </p>

      </v-card-text>

      <v-card-actions class="px-6 pb-8">
        <v-spacer></v-spacer>
        <v-btn
          v-if="!isDone"
          color="primary"
          class="px-5"
          large
          outlined
          depressed
          @click="cancel()"
        >{{ 'Cancel'}}</v-btn>
        <v-btn
          v-if="!isDone && products && products.length"
          color="primary"
          class="px-5"
          large
          depressed
          @click="start()"
        >Start</v-btn>
        <v-btn
          v-if="isDone"
          color="primary"
          class="px-5"
          large
          depressed
          @click="cancel()"
        >Close</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
/* eslint-disable */
import { inject, ref, watch } from 'vue'

export default {
  components: {
  },
  props: {
    value: Boolean,
    products: Array,
  },
  setup(props, context) {

    const api = inject('api')
    const productModel = api.model('products/odoo', {
    })

    const dialog = ref(false)

    const isBusy = ref(false)
    const isDone = ref(false)
    const isOk = ref(null)

    watch(() => props.value, val => {
      if (val) {
        isBusy.value = false
        isDone.value = false
        isOk.value = null
      }
      dialog.value = val
    })

    watch(dialog, val => {
      context.emit('input', val)
    })

    function start() {
      isBusy.value = true
      productModel.setAttribute('product_ids', props.products.map(product => product.id)).create().then(() => {
        isOk.value = true
      }).catch(() => {
        isOk.value = false
      }).finally(() => {
        isBusy.value = false
        isDone.value = true
      })
    }

    function cancel() {
      dialog.value = false
    }

    function show() {
      dialog.value = true
    }

    function hide() {
      dialog.value = false
    }

    return {
      dialog,

      start,
      cancel,

      show,
      hide,

      isBusy,
      isDone,
      isOk,
    }
  }}
</script>

<style scoped>

.image {
  height: 200px;
}

.image.bordered {
  border: 1px solid #eeeeee;
}
</style>