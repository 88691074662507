<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card>
      <v-toolbar color="primary" dark flat short class="px-2">
        <v-toolbar-title>Delete Supplier</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()" icon><v-icon>fas fa-times</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="mt-12">
        <v-form @submit.prevent>

          <v-select
            outlined
            clearable
            clear-icon="fas fa-times"
            class="mb-2"
            v-model="id"
            item-text="name"
            item-value="id"
            :items="suppliers"
            label="Supplier"
          ></v-select>



        </v-form>
      </v-card-text>

      <v-card-actions class="px-6 pb-8">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="px-5"
          large
          outlined
          depressed
          @click="cancel()"
        >Cancel</v-btn>
        <v-btn
          color="primary"
          class="px-5"
          large
          depressed
          :disabled="!id"
          @click="proceed()"
        >Delete</v-btn>
      </v-card-actions>

      <confirm-dialog
        ref="confirmDialog"
        @confirm="confirm"
      ></confirm-dialog>

    </v-card>

  </v-dialog>
</template>

<script>
import { ref, watch } from 'vue'
import ConfirmDialog from './ConfirmDialog.vue'

export default {
  components: {
    ConfirmDialog,
  },
  props: {
    value: Boolean,
    suppliers: Array,
  },
  setup(props, context) {
    const dialog = ref(false)
    const id = ref()
    const confirmDialog = ref()

    watch(() => props.value, val => {
      dialog.value = val
      if (val) {
        id.value = null
      }
    })

    watch(dialog, val => {
      context.emit('input', val)
    })

    function proceed() {
      if (id.value) {
        confirmDialog.value.show()
      }
    }

    function confirm() {
      context.emit('delete', id.value)
    }

    function cancel() {
      dialog.value = false
      context.emit('cancel')
    }

    function show() {
      id.value = null
      dialog.value = true
    }

    function hide() {
      dialog.value = false
    }

    return {
      dialog,

      id,
      confirmDialog,
      confirm,

      proceed,
      cancel,

      show,
      hide,
    }
  }}
</script>